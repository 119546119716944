import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {stateTransition} from '../../animations';
import {contextColors} from '../../theme-colors';
import {getScrollParent} from '../../../utils/dom.utils';

const animTriggerVoidLeft = {
    opacity: '0',
    width: '100%',
    height: '100%',
    top: '0',
    paddingLeft: '0',
    paddingRight: '0',
    left: '0',
    borderRadius: '.875em 50% 50% 50%',
};

const animTriggerVoidRight = {
    opacity: '0',
    width: '100%',
    height: '100%',
    top: '0',
    paddingLeft: '0',
    paddingRight: '0',
    left: '0',
    borderRadius: '50% .875em 50% 50%',
};

@Component({
    selector: 'app-hint',
    template: `
        <div class="hint-icon {{open ? '' : iconClasses}}" #icon [@active]="open ? appearance : null">
            <fas i="{{i}}"></fas>
        </div>
        <div *ngIf="open"
             [@reveal]="horizontalAlign"
             class="details floating {{horizontalAlign}}-align {{verticalAlign}}-align
             {{appearance == 'dark' ? 'bg-primary text-white' : 'bg-white text-body'}}">
            <div class="content text-left" style="width: {{contentWidth}}">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./hint.component.scss'],
    animations: [
        trigger('reveal', [
            transition('void => left', [
                style(animTriggerVoidLeft),
                animate(stateTransition),
            ]),
            transition('left => void', [
                animate(stateTransition, style(animTriggerVoidLeft)),
            ]),
            transition('void => right', [
                style(animTriggerVoidRight),
                animate(stateTransition),
            ]),
            transition('right => void', [
                animate(stateTransition, style(animTriggerVoidRight)),
            ]),
        ]),
        trigger('active', [
            state('dark', style({
                color: contextColors.brand_2,
                background: '#fff',
            })),
            state('light', style({
                color: '#fff',
                background: contextColors.primary,
            })),
            state('light-alt', style({
                color: '#fff',
                background: contextColors.primary,
            })),
            transition('* => *', animate(stateTransition)),
        ]),
    ],
})
export class HintComponent {
    @Input() appearance: 'dark' | 'light' | 'light-alt' = 'dark';
    @Input() iconClasses: string;
    @Input('horizontalAlign') explicitHorizontalAlign: 'left' | 'right';
    @Input('verticalAlign') explicitVerticalAlign: 'top' | 'bottom';
    @Input() i: string = 'question';
    @Input() contentWidth: string = '300px';
    open = false;
    horizontalAlign: 'left' | 'right' = 'left';
    verticalAlign: 'top' | 'bottom' = 'bottom';
    @ViewChild('icon', {static: true}) icon;

    @HostListener('mouseenter') onMouseEnter() {
        const elemRect: DOMRect = this.icon.nativeElement.getBoundingClientRect();
        const viewRect: DOMRect = getScrollParent(this.icon.nativeElement).getBoundingClientRect();
        this.horizontalAlign = this.explicitHorizontalAlign || (elemRect.left - viewRect.left < viewRect.right - elemRect.right ? 'left' : 'right');
        this.verticalAlign = this.explicitVerticalAlign || (elemRect.bottom - viewRect.top < viewRect.bottom - elemRect.top ? 'bottom' : 'top');
        this.open = true;
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.open = false;
    }
}
